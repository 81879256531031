@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
}